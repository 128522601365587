import * as React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';

import * as actionCreators from '@atom/actions/formTemplateActions';
import * as inventorySchemaActionCreators from '@atom/actions/inventorySchemaActions';
import ListViewTable from '@atom/components/common/listViewTable/ListViewTable';
import RenameModal from '@atom/components/common/RenameModal';
// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import { Icon, Menu, Modal, Progress } from '@atom/mui';
import { inventorySchemasSelector } from '@atom/selectors/inventorySchemasSelectors';
import colors from '@atom/styles/colors';
import layout from '@atom/styles/layout';
import {
  FormTemplateActions,
  InventorySchemaActions,
} from '@atom/types/actions';
import { FormTemplateInfo } from '@atom/types/form';
import { InventorySchemaItem } from '@atom/types/inventory';
import { InventorySchemasState, ReduxStore } from '@atom/types/store';
import {
  doesNotHaveRolePermissions,
  hasRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import history from '@atom/utilities/history';
import { incrementPage } from '@atom/utilities/listViewTableUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';

import RepositoryHeader from './RepositoryHeader';

// @ts-ignore
import leftPaneStyles from '../../styles/left-pane.css';
// @ts-ignore
import tableStyles from '../../styles/table.css';

/* Note that this is to satisfy the webpack build step which would
   automatically de-reference/scrub the import of styles as they aren't
   used anywhere in the code.  Passing them as magic strings to styleName
   is irrelevant

   For additional reading:
   https://github.com/gajus/babel-plugin-react-css-modules/issues/130
*/

// @ts-ignore
let styleReference = tableStyles;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
styleReference = leftPaneStyles;

const { MenuItem } = Menu;

const styles = {
  progress: {
    position: 'fixed',
    top: 0,
    right: 0,
    width: `calc(100vw - ${layout.sidePaneWidth})`,
    height: `calc(100vh - ${layout.fixedHeaderOffset})`,
  },
};

const displayHeaders = [
  'Form Title',
  'Status',
  'In Use',
  'Inventory Type',
  'Created By',
  'Created On',
  '',
];
const fields = [
  'name',
  'status',
  'inUse',
  'assetType',
  'createdBy',
  'createdDate',
  'additionalOptions',
];

interface ReduxStateProps {
  formTemplates: FormTemplateInfo[];
  formTemplatesTotal: number;
  loading: boolean;
  inventorySchemas: InventorySchemasState;
}

interface ReduxDispatchProps {
  formTemplateActions: FormTemplateActions;
  inventorySchemaActions: InventorySchemaActions;
}

type Props = ReduxStateProps & ReduxDispatchProps;

type ModalType = 'RENAME' | 'DELETE' | null;

interface State {
  page: number;
  limit: number;
  modalToOpen: { type: ModalType; id: any };
}

const initialState: State = {
  page: 1,
  limit: 25,
  modalToOpen: { type: null, id: null },
};

class FormRepository extends React.Component<Props, State> {
  state = initialState;

  componentDidMount() {
    const {
      formTemplateActions,
      inventorySchemas,
      inventorySchemaActions,
    } = this.props;
    const { page, limit } = this.state;

    if (R.isNil(inventorySchemas) || R.isEmpty(inventorySchemas)) {
      inventorySchemaActions.retrieveInventorySchemas({
        rootSchemas: true,
      });
    }

    formTemplateActions.getFormTemplates({ page, limit });
  }

  toggleModal = (modalType: ModalType, rowId: any) => {
    this.setState({ modalToOpen: { type: modalType, id: rowId } });
  };

  incrementPage = (increment: number) => {
    const { formTemplateActions } = this.props;
    const { page, limit } = this.state;

    this.setState(incrementPage(increment));
    formTemplateActions.getFormTemplates({ page: page + increment, limit });
  };

  updateLimit = (limit: number) => {
    const { formTemplateActions } = this.props;
    const page = 1;

    this.setState({ limit, page });
    formTemplateActions.getFormTemplates({ page, limit });
  };

  navigateToTemplate = (formId: string, isPublished: boolean) => {
    const preview =
      isPublished || doesNotHaveRolePermissions(ROLE_SETS.MANAGER)
        ? '/preview'
        : '';

    history.push(`/form-builder/${formId}${preview}`);
  };

  removeFormTemplate = (id: string): boolean => {
    const { formTemplateActions } = this.props;
    formTemplateActions.requestFormTemplateDelete(id);

    return true;
  };

  duplicateFormTemplate = (formTemplateId: string) => {
    const { formTemplateActions } = this.props;
    formTemplateActions.requestDuplicateFormTemplate(formTemplateId);
  };

  renameFormTemplate = (id: string, name: string) => {
    const { formTemplateActions } = this.props;

    const data = {
      id,
      name,
    };

    formTemplateActions.requestRenameFormTemplate(data);
  };

  buildFormCell = (row: FormTemplateInfo, field: string, index: number) => {
    const { inventorySchemas } = this.props;
    const { modalToOpen } = this.state;

    const matchedSchema = inventorySchemas.find(
      (schema: InventorySchemaItem): boolean => schema.id === row.schemaId,
    );

    const rootSchema = matchedSchema ? matchedSchema : {};

    // @ts-ignore
    const assetType = row.schemaId ? rootSchema.name : 'All';

    const status = row.isPublished
      ? { icon: 'lock', text: 'Published' }
      : { icon: 'edit', text: 'Draft' };

    const firstName = R.pathOr('', ['firstName'], row.createdBy);
    const lastName = R.pathOr('', ['lastName'], row.createdBy);

    const fieldTemplates = {
      name: (
        <td
          key={index}
          onClick={(): void => this.navigateToTemplate(row.id, row.isPublished)}
          styleName="tableStyles.clickable"
        >
          {row.name}
        </td>
      ),
      status: (
        <td key={index}>
          <div styleName="tableStyles.status-cell">
            <Icon color={colors.neutral.gray}>{status.icon}</Icon>
            <div styleName="tableStyles.status-text">{status.text}</div>
          </div>
        </td>
      ),
      inUse: <td key={index}>{row.instanceCount}</td>,
      assetType: <td key={index}>{assetType}</td>,
      createdBy: <td key={index}>{`${firstName} ${lastName}`}</td>,
      createdDate: <td key={index}>{setDisplayDate(row.createdDate)}</td>,
      additionalOptions: (
        <td key={index} styleName="tableStyles.additional-options">
          {hasRolePermissions(ROLE_SETS.MANAGER) && (
            <>
              <Menu>
                <MenuItem
                  startAdornment={
                    <Icon>
                      <img src={renameIcon} />
                    </Icon>
                  }
                  onClick={() => this.toggleModal('RENAME', row.id)}
                >
                  Rename
                </MenuItem>
                <MenuItem
                  startAdornment={<Icon>content_copy</Icon>}
                  onClick={() => this.duplicateFormTemplate(row.id)}
                >
                  Duplicate
                </MenuItem>
                {!row.instanceCount && (
                  <MenuItem
                    startAdornment={<Icon>delete</Icon>}
                    onClick={() => this.toggleModal('DELETE', row.id)}
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
              <RenameModal
                open={
                  modalToOpen.type === 'RENAME' && modalToOpen.id === row.id
                }
                closeModal={() => this.toggleModal(null, null)}
                id={row.id}
                name={row.name}
                type="form Template"
                renameAction={this.renameFormTemplate}
              />
              {!row.instanceCount && (
                <Modal
                  open={
                    modalToOpen.type === 'DELETE' && modalToOpen.id === row.id
                  }
                  title="Delete Form Template"
                  onCancel={() => this.toggleModal(null, null)}
                  confirmButtonText="Delete"
                  ConfirmButtonProps={{
                    style: { background: colors.brand.red },
                  }}
                  onConfirm={() => {
                    this.removeFormTemplate(row.id);
                    this.toggleModal(null, null);
                  }}
                >
                  Are you sure you want to delete this form template?
                </Modal>
              )}
            </>
          )}
        </td>
      ),
    };
    return fieldTemplates[field] || <td key={index}>{row[field]}</td>;
  };

  render() {
    const { formTemplates, formTemplatesTotal, loading } = this.props;
    const { page, limit } = this.state;

    const mainPaneContent = loading ? (
      <div>
        <Progress style={styles.progress} />
      </div>
    ) : (
      <ListViewTable
        displayHeaders={displayHeaders}
        dataRows={formTemplates}
        fields={fields}
        // @ts-ignore
        buildCell={this.buildFormCell}
        totalRows={formTemplatesTotal}
        // @ts-ignore
        incrementPage={this.incrementPage}
        // @ts-ignore
        updateLimit={this.updateLimit}
        page={page}
        limit={limit}
      />
    );

    return (
      <div>
        <RepositoryHeader />
        <div styleName="leftPaneStyles.left-pane" />
        {mainPaneContent}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  formTemplates: state.formTemplates,
  loading: state.loading.loadingFormTemplates,
  formTemplatesTotal: state.formTemplatesTotal,
  inventorySchemas: inventorySchemasSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  formTemplateActions: bindActionCreators(actionCreators, dispatch),
  inventorySchemaActions: bindActionCreators(
    inventorySchemaActionCreators,
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormRepository);
